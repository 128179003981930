import { useState, useEffect } from "react"

function useWindowSize() {

  const [ww, setWw] = useState(window.innerWidth)
  const [wh, setWh] = useState(window.innerHeight)

  useEffect(() => {
    // function to update screenWidth & screenHeight states
    function handleResize() {
      setWw(window.innerWidth)
      setWh(window.innerHeight)
    }

    // listen to resize event and update states
    window.addEventListener("resize", handleResize)

    // cleanup function to safely remove eventListener
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { ww, wh }
}

export default useWindowSize