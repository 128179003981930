import React from 'react'
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent'
import Image from '../Image/Image'
import Gallery from '../Gallery/Gallery'

const PageContent = ({contentItems}) => {
  return (
    contentItems?.map((el, i) => {
      return (
        <>
          {el.acf_fc_layout === 'buro_content_txt' &&
            <div key={i} className={`relative md:w-${el.content_width}-12 mb-8 `}>            
              {el.content_elements?.map(el => (
                <>
                  {el.images?.length > 1 ? <Gallery images={el.images} arrows={false} autoplay={true} autoPlayActive={true} ratio={parseFloat(el.images[0]?.height / el.images[0]?.width).toFixed(2)} /> : <Image img={el.images ? el.images[0] : null} isModal={false} />}
                  {el.txt && <div className='my-3' dangerouslySetInnerHTML={{__html: el.txt}}></div>}
                </>
              ))}
            </div>}

          {(el.acf_fc_layout === 'buro_content_accordion') &&
            <div key={i} className={`relative ${el.content_width === '12' ? 'w-full' : `md:w-${el.content_width}-12`}`}>
              <CollapsibleContent 
                elKey={i} 
                isOpen={null}
                collapsibleHeader={el.content_accordion_headline} 
                collapsibleBody={el.content_accordion_el} 
                type={'page'}
              /> 
            </div>}
        </>
      )
    })
  )
}

export default PageContent


// switch (el.acf_fc_layout) {
//   case 'buro_content_txt':
//     return (
//       <div 
//         key={i} 
//         id={i} 
//         // ref={el => contentRefs.current[i] = el} 
//         className={`relative md:w-${el.content_width}/12 mb-8 text-sm text-white text-left`}
//       >            
//         {el.content_elements?.map(el => (
//           <>
//             {el.images?.length > 1 ? <Gallery images={el.images} autoplay={true} /> : <Image img={el.images ? el.images[0] : null} isModal={false} />}
//             {el.txt && <div dangerouslySetInnerHTML={{__html: el.txt}}></div>}
//           </>
//         ))}
//       </div>
//     )
  
//   case 'buro_content_accordion':
//     return (
//       <div 
//         key={i} 
//         id={i} 
//         // ref={el => contentRefs.current[i] = el} 
//         className={`relative ${el.content_width === '12' ? 'w-full' : `md:w-${el.content_width}/12`} text-sm text-white text-left`}
//       >
//         <CollapsibleContent collapsibleHeader={el.content_accordion_headline} collapsibleBody={el.content_accordion_el} type={'page'} elKey={i} isOpen={null} />
//       </div>
//     )
  
  // case 'buro_content_img':
  //   return (
  //     <div 
  //       id={i} 
  //       key={i} 
  //       // ref={el => contentRefs.current[i] = el} 
  //       className={`relative ${el.buro_content_col_size === '12' ? 'w-full' : `md:w-${el.buro_content_col_size}/12`} mb-8 text-sm text-white text-left`}
  //     >
  //       {el.buro_img && <Image img={el.buro_img} isModal={false} />}
  //     </div>
  //   )
  
  // default:
  //   return null
// }