import React, { useContext, useEffect, useRef, useState } from 'react'
import { Outlet, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import Logo from '../Logo/Logo'
import Menubar from '../Menubar/Menubar'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ThemeContext } from '../../ThemeContext'
import { PageScrolledContext } from '../../PageScrolledContext'
import { ProjectsHeadlineContext } from '../../ProjectsHeadlineContext'
import { Helmet } from 'react-helmet'
import { useWindowScroll } from '@uidotdev/usehooks'

import faviconP from '../../assets/favicon-p.ico'
import faviconW from '../../assets/favicon-w.ico'
import faviconS from '../../assets/favicon-s.ico'

const Layout = () => {

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  const { theme, setIntro, introEnd, setIntroEnd, setShowLogo, setTheme, setLoading } = useContext(ThemeContext)
  const { scroll, setScroll } = useContext(PageScrolledContext)
  const { setShowProjectHeadline } = useContext(ProjectsHeadlineContext)

  const [{ y }] = useWindowScroll()
  
  const { slug } = useParams()
  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')
  
  const refWrapper = useRef(null)

  const favicons = [faviconP, faviconW, faviconS]
  const [counter, setCounter] = useState(0)
  const [viewCount, setViewCount] = useState(0)
  const [menuData, setMenuData] = useState(null)
  const [categories, setCategories] = useState(null)
  const [filter, setFilter] = useState(null)
  const [singleProjectSubtitle, setSingleProjectSubtitle] = useState(null)
  const [pageBgColor, setPageBgColor] = useState(null)

  const navigate = useNavigate()

  const params = {category: filter}

  const handleFilter = filter => {
    setFilter(null)
    setFilter(filter)
  }  
  
  useEffect(() => {
    if (splitLocation[1] === 'projekte' || splitLocation[1] === 'projekt') {
      if (filter === null) {
        navigate({pathname: pathname}) //'/projects'
      } else {
        navigate({
          pathname: pathname, //'/projects',
          search: `?${createSearchParams(params)}`
        })
      }
    }
  }, [filter])
  
  useEffect(() => {
    setScroll({
      isScrolled: y > 1 ? true : false,
      scrollPos: y,
      // scrollDirection: self?.direction,
    })  
  }, [y])

  useEffect(() => {
    setLoading(true)
    setViewCount(viewCount + 1)
    setSingleProjectSubtitle(null)

    fetch('https://cms.pws.eu/wp-json/pws/v1/menuItems')
      .then(res => res.json())
      .then(data => setMenuData(data['menu']))

    if (pathname === '/') {
      setTheme(false)
      setShowLogo(true)
      setPageBgColor(false)

    } else if (splitLocation[1] === 'projekte') {
      setTheme(false)
      setShowProjectHeadline(true)
      setShowLogo(viewCount > 0 ? false : true) 
      setPageBgColor(false)

      fetch('https://cms.pws.eu/wp-json/wp/v2/projectcategory?per_page=100')
        .then(res => res.json())
        .then(data => setCategories(data))

    } else if (splitLocation[1] === 'projekt') {
      setFilter(null)
      setTheme(false)
      setShowProjectHeadline(false)
      setShowLogo(false) 
      setPageBgColor(false)
      // setShowLogo(viewCount >= 1 ? false : true) 

      fetch(`https://cms.pws.eu/wp-json/wp/v2/projects/?slug=${slug}`)
        .then(res => res.json())
        .then(data => {
          setSingleProjectSubtitle(data[0]?.acf.project_subtitle)
          // ScrollTrigger.refresh()
        })

      fetch('https://cms.pws.eu/wp-json/wp/v2/projectcategory?per_page=100')
        .then(res => res.json())
        .then(data => setCategories(data))

    } else if (splitLocation[1] === 'page') {
      setLoading(true)
      setShowLogo(true)
      setTheme(true)

      fetch(`https://cms.pws.eu/wp-json/wp/v2/pages/?slug=${slug}`) 
        .then((res) => res.json())
        .then((data) => {
          setTheme(data[0]?.acf.page_bg_black ? true : false)
          setPageBgColor(data[0]?.acf.page_bg_black)
          setLoading(false)
        })
    }
  }, [slug, pathname])

  useEffect(() => {
    setShowLogo(true)
    setIntro(false)
    setTimeout(() => setIntroEnd(true), 3500)
    // setTimeout(() => setIntro(false), 250)
    const interval = setInterval(() => setCounter((prevCounter) => prevCounter === 2 ? 0 : prevCounter + 1), 1000)
    return () => clearInterval(interval)
  }, [])
  
  return (
    <div ref={refWrapper}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{}Peter W. Schmidt Architekten</title>
        <meta name="description" content={'Peter W. Schmidt Architekten entwirft, plant und baut Wohnungsbauvorhaben, Bildungs- und Kulturbauten bis hin zu Industriebauten, Häusern und Stadträumen.'} />
        <link rel='icon' type='image' href={favicons[counter]} />
      </Helmet>
      
      <Logo 
        theme={theme} 
        viewCount={viewCount} 
      />

      <Menubar
        menuData={menuData}
        isScrolled={scroll?.isScrolled}
        categories={categories}
        filter={filter}
        handleFilter={handleFilter}
        singleProjectSubtitle={singleProjectSubtitle}
      />

      <div className={`pws-content snap-none relative ${pageBgColor ? 'text-white' : 'text-black'}`}>
        {introEnd && <Outlet />}
      </div>
    </div>
  )
}

export default Layout

// const logoScrollTopPos = () => {
//   ScrollTrigger.create({
//     trigger: refWrapper.current,
//     start: 'top top',
//     // end: `top top-=100vh`,
//     scrub: true,
//     // markers: true,
//     onUpdate: self => {
//       (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
//     }
//   })    
// }

// useLayoutEffect(() => {
//   let ctx = gsap.context(() => {
//     ScrollTrigger.create({
//       trigger: refWrapper.current,
//       start: 'top top',
//       // end: 'bottom bottom+=10px',
//       // markers: true,
//       onUpdate: self => {
//         setScroll({
//           isScrolled: self?.scroll() ? true : false,
//           scrollPos: self?.scroll(),
//           scrollDirection: self?.direction,
//         })  
//         // (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
//         // self.scroll() >= 10 && setIsScrolled(true)
//       }
//     })
//   }, refWrapper.current)

//   return () => ctx.revert()
// }, [])

// useLayoutEffect(() => {
//   (splitLocation.includes('projekt') && scroll?.scrollPos === 1 && scroll.scrollPos >= size?.height/5) && setShowLogo(false) 
// }, [scroll])