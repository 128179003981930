import React, { useState, useRef, useEffect, useContext, useLayoutEffect }  from 'react'
import Image from '../Image/Image'
import { useParams } from 'react-router-dom'
import Gallery from '../Gallery/Gallery'
import ProjectData from '../ProjectData/ProjectData'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ProjectsHeadlineContext } from '../../ProjectsHeadlineContext'
import { ThemeContext } from '../../ThemeContext'
import { PageScrolledContext } from '../../PageScrolledContext'
import Projects from '../Projects/Projects'
import Video from '../Video/Video'
import { useWindowSize } from '@uidotdev/usehooks'

const SingleProject = () => {

  gsap.registerPlugin(ScrollTrigger)

  const { contentWidth, isLoading, setLoading} = useContext(ThemeContext)
  const { showProjectHeadline, setShowProjectHeadline } = useContext(ProjectsHeadlineContext)
  const { scroll } = useContext(PageScrolledContext)

  const { slug } = useParams()

  const size = useWindowSize()

  const contentRefs = useRef([])
  const refProjectsHeadline = useRef(null)
  const refSingleProject = useRef(null)
  const [singleProjectTitle, setSingleProjectTitle] = useState(null)
  const [singleProjectSubtitle, setSingleProjectSubtitle] = useState(null)
  const [singleProjectContent, setSingleProjectContent] = useState(null)
  const [hoveredTxt, setHoveredTxt] = useState(null)
  const [projectsHeadlineScrollTrigger, setProjectsHeadlineScrollTrigger] = useState(null)
  
  const offset = el => {
    var rect = el.getBoundingClientRect(),
    scrollLeft = document.documentElement.scrollLeft,
    scrollTop = document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  useEffect(() => {  
    // setShowProjectHeadline(false)

    gsap.to(window, {
      duration: .25,
      scrollTo: 0,
    })

    fetch(`https://cms.pws.eu/wp-json/wp/v2/projects/?slug=${slug}`)
      .then(res => res.json())
      .then(data => {
        setSingleProjectTitle(data[0]?.title.rendered)
        setSingleProjectSubtitle(data[0]?.acf.project_subtitle)
        setSingleProjectContent(data[0]?.acf)
        setLoading(false)

        // const showProjectHeadlineTrigger = 
        // return () => showProjectHeadlineTrigger.kill() //ctx.revert() 
      })  
  }, [slug])

  // useEffect(() => {
  //   // projectHeadlineScrollTrigger(refProjectsHeadline.current)
  //   // console.log(singleProjectTitle, singleProjectSubtitle, singleProjectContent)
  //   // setSingleProjectImages(singleProjectContent?.project_content.filter(item => item.acf_fc_layout === 'project_content_img'))
  // }, [isLoading])

  // useLayoutEffect(() => {  
  //   setShowProjectHeadline(scroll?.scrollPos >= projectsHeadlineScrollTrigger ? true : false)
  // }, [scroll])

  // useLayoutEffect(() => {  
  //   // console.log('ProjectsHeadlineScrollTrigger --> refProjectsHeadline.current', Math.floor(offset(refProjectsHeadline.current).top))
  //   setTimeout(() => setProjectsHeadlineScrollTrigger(Math.floor(offset(refProjectsHeadline.current).top - 28)), 1000)
  //   return () => {}
  // }, [singleProjectContent])

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(refProjectsHeadline.current, {
        opacity: 0,
        duration: .2,
        scrollTrigger: {
          trigger: refSingleProject.current,
          toggleActions: 'play reset restart reset',
          start: `bottom top+=${refProjectsHeadline.current.offsetHeight + 28}px`,
          end: `bottom top`,
          onEnter: () => setShowProjectHeadline(true),
          onEnterBack: () => setShowProjectHeadline(true),
          onLeaveBack: () => setShowProjectHeadline(false)
          // markers: true,
        }
      })
      // ScrollTrigger.create({
      //   trigger: refSingleProject.current,
      //   toggleActions: 'play reset restart reset',
      //   start: `bottom top+=${refProjectsHeadline.current.offsetHeight + 28}px`,
      //   end: `bottom top`,
      //   markers: true,
      //   // onEnter: () => setShowProjectHeadline(true),
      //   // onEnterBack: () => setShowProjectHeadline(true),
      //   // onLeaveBack: () => setShowProjectHeadline(false)
      // })
    })    
    
    return () => ctx.revert()
  }, [singleProjectContent])

  return (
    <>
      <div ref={refSingleProject} className='relative flex flex-wrap justify-center min-h-screen pt-11 md:pt-[17.5vh] px-5 md:px-8 lg:px-12 xl:px-24'>
        
        {!isLoading && singleProjectContent?.project_content.map((el, i) => {
          switch (el.acf_fc_layout) {
            case 'project_content_img':
              return (
                <>
                  <div 
                    key={i} 
                    ref={el => contentRefs.current[i] = el} 
                    className={`w-full md:w-[${el.project_img_width + `%`}] mb-8 md:mb-16 lg:mb-24 xl:mb-32 relative px-1 lg:cursor-pwsPlus`} 
                    // ${i === singleProjectContent.project_content.length - 1 ? `mb-[33vh]`
                    // style={{width: el.project_img_width + '%'}}
                  >
                    {el.project_img && <Image img={el.project_img} isModal={true} contentIndex={i} />}
                  </div>

                  {i === 0 && 
                    <div key='title' className='relative w-full mb-8'>
                      <div className=' md:m-auto font-folioMedium' style={ {width: size.width > 640 ? contentWidth : 'auto'}}>
                        <h1 className='mb-0 text-base md:text-lg singleProjectW' dangerouslySetInnerHTML={{__html: singleProjectTitle}}></h1>

                        <h2 className='mb-4 text-base singleProjectW'>
                          {singleProjectContent.project_location}
                        </h2>
                      </div>
                    </div>}
                </>
              )
            
            case 'project_content_gallery':
              return (
                <>
                  <div 
                    key={i}
                    ref={el => contentRefs.current[i] = el} 
                    // className={`${i === singleProjectContent.project_content.length -1 ? `mb-[33vh]` : `mb-8 md:mb-16 lg:mb-24 xl:mb-32`} relative w-full md:w-screen`}>
                    className='relative w-full md:h-[82.5vh] mb-8 md:mb-16 lg:mb-24 xl:mb-32'>
                      <Gallery 
                        images={el.gallery_images} 
                        contentIndex={i} 
                        fullscreen={true} 
                        arrows={true} 
                        autoplay={true} 
                        autoPlayActive={true} 
                        pagination={true} 
                        isPost={false} 
                      />
                  </div>

                  {i === 0 && 
                    <div key='title' className='relative w-full mb-8'>
                      <div className=' md:m-auto font-folioMedium' style={{width: size.width > 640 ? contentWidth : 'auto'}}>
                        <h1 className='mb-0 text-base md:text-lg singleProjectW' dangerouslySetInnerHTML={{__html: singleProjectTitle}}></h1>

                        <h2 className='mb-4 text-base singleProjectW'>
                          {singleProjectContent.project_location}
                        </h2>
                      </div>
                    </div>}
                </>
              )
            
            case 'project_content_txt':
              return (
                <>
                  {i === 0 && 
                    <div key='title' className='relative w-full mb-8'>
                      <div className='md:m-auto font-folioMedium' style={{width: size.width > 640 ? contentWidth : null}}>
                        <h1 className='mb-0 text-base md:text-lg singleProjectW' dangerouslySetInnerHTML={{__html: singleProjectTitle}}></h1>

                        <h2 className='mb-4 text-sm md:text-base singleProjectW'>
                          {singleProjectContent.project_location}
                        </h2>
                      </div>
                    </div>}

                    <div 
                      key={i} 
                      ref={(el) => contentRefs.current[i] = el}
                      // className={`${i === singleProjectContent.project_content.length -1 ? `mb-[33vh]` : `mb-8 md:mb-16 lg:mb-24 xl:mb-32`} relative w-full project-txt`}
                      className={`relative w-full ${singleProjectContent?.project_content[i+1].acf_fc_layout === 'project_content_data' ? 'mb-4' : 'mb-8 md:mb-12 lg:mb-16'} xl:mb-24 project-txt`}
                      onMouseEnter={() => setHoveredTxt(contentRefs.current[i])}
                      onMouseLeave={() => setHoveredTxt(null)}
                    >
                      <div className='relative text-sm text-left md:text-base singleProjectW md:m-auto' style={{width: size.width > 640 ? contentWidth : null}}>
                        <ReadMoreText text={el.project_txt} type={'project'} isText={true} hoveredTxt={hoveredTxt} parentEl={contentRefs.current[i]} />
                      </div>
                    </div>
                </>
              )
            
            case 'project_content_data':
              return (
                <>
                  {i === 0 && 
                    <div key='title' className='relative w-full mb-8'>
                      <div className=' md:m-auto font-folioMedium' style={{width: contentWidth}}>
                        <h1 className='mb-0 text-base md:text-lg singleProjectW' dangerouslySetInnerHTML={{__html: singleProjectTitle}}></h1>

                        <h2 className='mb-4 text-base singleProjectW'>
                          {singleProjectContent.project_location}
                        </h2>
                      </div>
                    </div>}

                  <div 
                    key={i} 
                    ref={(el) => contentRefs.current[i] = el} 
                    // className={`${i === singleProjectContent.project_content.length - 1 ? `mb-[33vh]` : `mb-8 md:mb-16 xl:mb-32`} relative w-full project-data`}
                    className='relative w-full mb-8 md:mb-16 lg:mb-24 xl:mb-32 project-data'
                  >
                    <ProjectData projectData={el.project_content_data_table} />
                  </div>
                </>
              )

            case 'project_content_space':
              return <div key={i} ref={el => contentRefs.current[i] = el} className='relative' style={{width: el.project_space_width + '%'}}></div>

            case 'project_content_video':
              return (
                <div key={i} ref={el => contentRefs.current[i] = el} className='relative' style={{width: el.project_video_width + '%'}}>
                  <Video src={el.project_video} />
                </div>
              )
            
            default:
              return null
          }
        })}  

        <div className='w-full md:mt-[33vh]'>
          <div className='w-full mx-auto' style={{width: size.width > 640 ? contentWidth : null}}>
            <div className='hidden w-1/3 md:block'/>

            <h1 ref={refProjectsHeadline} className={`relative top-0 inset-x-0 w-full md:w-5/12 2xl:w-1/3 mx-auto md:px-4 xl:px-12 text-base text-center uppercase font-folioMedium`}>
              {/* ${showProjectHeadline ? 'opacity-0' : 'opacity-1'} */}
              Projekte
            </h1>
            
            <div className='hidden w-1/3 md:block'/>
          </div>
        </div>

      </div>

      <div className='relative flex flex-wrap w-full'>        
        <Projects />        
      </div>
    </>
  )
}

export default SingleProject