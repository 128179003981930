import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'
import Searchbar from '../Search/Searchbar'
import Search from '../Search/Search'
import { ProjectsHeadlineContext } from '../../ProjectsHeadlineContext'
import { isMobile } from 'react-device-detect'
import { useWindowSize } from '@uidotdev/usehooks'
import { gsap } from 'gsap'
import Filter from '../Filter/Filter'

function Menubar({children, menuData, categories, filter, handleFilter, singleProjectSubtitle}) {

  const { height } = useWindowSize()
  const { dark, introEnd, menuOpen, setMenuOpen, contentWidth } = useContext(ThemeContext)
  const { showProjectHeadline } = useContext(ProjectsHeadlineContext)

  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')
  
  const refNav = useRef(null)
  const refMenu = useRef(null)

  const [isSearch, setIsSearch] = useState(false)  
  const [hoveredFilter, setHoveredFilter] = useState(null)
  const [menuBtnHeight, setMenuBtnHeight] = useState(null)

  const toggleSearch = val => setIsSearch(val)

  // useEffect(() => {
  // }, [intro])
  // setIntroEnd(true)

  useEffect(() => {
    setMenuOpen(false)
    setIsSearch(false)
  }, [pathname])

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(refNav.current, {
        duration: .3,
        height: menuOpen ? height : menuBtnHeight,
        ease: 'linear',
      })        
    })
    
    return () => ctx.revert()
  }, [menuOpen]) 

  return (
    <>
      <div className={`fixed top-0 z-30 inset-x-0 md:overflow-hidden ${introEnd ? 'opacity-1' : 'opacity-0'} transition-opacity duration-300`}>
        <div 
          ref={refNav}
          className={`${dark ? 'text-white' : 'text-black'} flex flex-col relative w-full ${menuOpen ? `backdrop-blur-md ${dark ? 'bg-black/70 opacity-90' : 'bg-white/70 md:bg-transparent opacity-95'}` : ''} `} 
          style={splitLocation[1] !== 'projects' ? {width: menuOpen ? '100%' : contentWidth, margin: 'auto'} : undefined}
          // m-auto md:px-4 xl:px-12 transition-all duration-500
          // style={splitLocation[1] === 'page' ? {width: contentWidth} : null} ${splitLocation[1] === 'page' ? 'justify-between' : 'justify-centers'}
        >
          <div 
            ref={refMenu}
            className={`relative flex flex-wrap justify-center w-full md:w-1/3 lg:w-2/5 2xl:w-5/12 md:mx-auto h-auto pb-3 md:pb-0 md:pt-5 lg:pt-4 z-20`} 
            onMouseLeave={() => !isMobile && setMenuOpen(false)}
            // style={{ width: `${size.width > 640 ? refMenu.current.offsetWidth+'px' : null}`}}
            // onClick={() => setMenuOpen(menuOpen ? null : refMenu.current)}
          >
            <Menu 
              menuData={menuData} 
              currentLocation={splitLocation} 
              isSearch={isSearch} 
              toggleSearch={toggleSearch} 
              showProjectHeadline={showProjectHeadline} 
              singleProjectSubtitle={singleProjectSubtitle}
              menuBtnHeight={menuBtnHeight} 
              setMenuBtnHeight={setMenuBtnHeight}
            />            
          </div>
          
          {/* <div className='flex justify-center cursor-pointer'>
            <div 
              className='relative z-30 flex flex-col justify-center w-10 h-8 p-2 space-y-1'
              onClick={() => isMobile && setMenuOpen(!menuOpen)}
            >
              <span className={`block w-6 h-0.5 ${dark ? 'bg-white' : 'bg-black'} ${isMobile && menuOpen ? 'translate-y-[6px] rotate-45' : ''} transition-transform origin-center duration-150`} aria-hidden='true'></span>
              <span className={`block w-6 h-0.5 ${dark ? 'bg-white' : 'bg-black'} ${isMobile && menuOpen ? '-rotate-45' : ''} transition-transform origin-center duration-150`} aria-hidden='true'></span>
            </div>
          </div> */}
          
          {children}
        </div>
      </div>

      <div className={`flex justify-between px-3 mt-20 md:mt-12 ${introEnd ? 'opacity-1' : 'opacity-0'} transition-opacity duration-300`}>
        <Filter 
          categories={categories} 
          hoveredFilter={hoveredFilter} 
          setHoveredFilter={setHoveredFilter} 
          filter={filter} 
          handleFilter={handleFilter} 
          menuOpen={menuOpen} 
          isSearch={isSearch} 
        />

        {(splitLocation[1] === 'projekte' || splitLocation[1] === 'projekt') && 
          <div className={`${splitLocation[1] === 'projekt' ? (showProjectHeadline ? 'visible' : 'invisible') : ''} relative md:fixed self-start w-1/3 cursor-pointer md:right-3 md:top-0 md:pt-7 md:flex md:justify-center md:align-top md:z-30`}> {/* ${!isMobile && !menuOpen ? '' : ''} */}
            <>
              {!isSearch && <Search toggleSearch={toggleSearch} />}
              {isSearch && <Searchbar isSearch={isSearch} toggleSearch={toggleSearch} />}
            </>
          </div>}
      </div>
    </>
  )
}

export default Menubar

function Menu({ menuData, setMenuBtnHeight, singleProjectSubtitle }) {

  const { dark, menuOpen, setMenuOpen } = useContext(ThemeContext)

  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')

  const refTitle = useRef(null)
  const refMenuBtn = useRef(null)
  const refMenuItems = useRef(null)
  const tlMenuItems = useRef()

  useEffect(() => {
    setMenuBtnHeight(refMenuBtn.current.offsetHeight + 16)
  }, [refMenuBtn.current])

  useEffect(() => {
    const ctx = gsap.context(() => {

      gsap.to(refTitle.current, {
        duration: .25,
        autoAlpha: menuOpen ? 0 : 1,
        ease: 'linear',
        overwrite: 'auto'
      })
      
      let q = gsap.utils.selector(refMenuItems)
      tlMenuItems.current = gsap.timeline().from(
        q('.navitem'), { 
          autoAlpha: menuOpen ? 1 : 0, 
          scale: .25,
          ease: 'power3.out',//linear
          duration: .15,
          stagger: .05,
          overwrite: 'auto'
        }
      )        
    })
    
    return () => ctx.revert()
  }, [menuOpen]) 

  return (
    <>
      <div 
        ref={refMenuItems} 
        className={`relative flex flex-col w-full ${menuOpen ? 'h-auto pt-3 md:pt-0 md:mt-3 md:mb-12' : 'h-0'} gap-y-4 md:gap-y-1 px-3 md:px-0 whitespace-nowrap overflow-hidden transition-all duration-300 md:text-center uppercase text-sm lg:text-base menu`}
      >
        {menuData?.map((item, i) => {        
          const type = item.type
          const href = item.href
          const bold = item.bold
          const txt = item.txt

          return (
            type === 'spacer' ? 
              <div key={i} className='w-full md:my-2'></div>
              :
              <NavLink key={i} to={`${href}`} target={type === 'externalLink' ? '_blank' :''} className='relative block my-2 text-center navitem'>
                <span className={`block ${type === 'externalLink' ? 'text-green' : ''} ${bold ? 'font-folioMedium' : 'font-folioLight'} transition-transform duration-150 md:hover:scale-105`}>
                  {txt}
                </span>
              </NavLink>
          )
        })}
      </div>

      <div 
        ref={refMenuBtn} 
        className={`w-full md:w-auto md:px-3 py-3 mb:pb-6 font-folioMedium text-center uppercase text-sm md:text-[22px] ${!menuOpen ? `backdrop-blur-md ${dark ? 'bg-black/70 opacity-90' : 'bg-white/70 md:bg-transparent opacity-95'}` : ''}`}
        onMouseEnter={() => !isMobile && setMenuOpen(true)}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <p ref={refTitle} className={`block pb-3 font-folioMedium whitespace-nowrap transition-transform duration-150 md:hover:scale-105`}>
          {splitLocation[1] === 'projekt' ? singleProjectSubtitle : 'Peter W. Schmidt Architekten'}
        </p>
        
        <div className='flex justify-center w-full'>
          <button 
            type='button'
            className={`relative block ${dark ? 'text-white' : 'text-black'} focus:outline-none focus:ring-0 focus:ring-inset-0 rotate-45`}
          >
            <svg className='w-3 h-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
              {menuOpen ? <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 24 L 24 0"/> : <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" /> }
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}






// <div ref={refFilterParent} className={`fixed top-12 ${filter === null ? 'left-3' : 'left-9'} md:relative md:pt-7 md:top-auto md:left-auto md:z-10 w-1/2 md:w-1/3 cursor-pointer hidden md:block ${isMobile && isSearch ? 'md:hidden' : ''}`}>
//   {showProjectHeadline &&
//     <div
//       className={'relative z-40 inline-block -translate-x-6 md:-translate-x-1/2'}
//       style={{transform: size.width > 640 ? `translateX(${filterOffset}px)` : null}}
//       onMouseEnter={() => setHoveredFilter(refFilter.current)}
//       onMouseLeave={() => setHoveredFilter(null)}
//       onClick={() => isMobile ? setHoveredFilter(hoveredFilter ? null : refFilter.current) : null}
//     >
//       <div className={`relative flex flex-nowrap md:align-top w-full overflow-visible ${hoveredFilter ? 'block backdrop-blur-md' : ''}`}> 
//         <p ref={refFilterBtn} className={`${hoveredFilter ? 'w-full md:w-auto' : 'w-auto'} relative uppercase ml-6 md:ml-0 px-2 md:px-3 whitespace-nowrap`}> {/* border-b */}
//           {filter === null ? <span className='text-medium font-folioMedium'>Filter</span> : filter}
//         </p>

//         {filter !== null &&
//           <button 
//             type="button" 
//             className="absolute left-0 z-10 inline-flex items-center justify-center w-4 h-4 px-2 text-black right-6 md:right-auto top-1 md:-left-6 hover:text-black focus:outline-none focus:ring-0 focus:ring-inset-0"
//             onClick={() => handleFilter(null)}
//           >
//             <svg className="absolute w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//               <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
//             </svg>
//           </button>}
//       </div>

//       <ul ref={refFilter} className={`${hoveredFilter ? 'block backdrop-blur-sm' : 'hidden'} relative pt-2 pl-6 md:pl-3 px-3 pb-4 z-50 w-full flex flex-wrap flex-col cursor-pointer`} style={{transform: `translateX(${-refFilterBtn.current?.offsetWidth})`}}>
//         {categories?.map((el, i) => {
//           return (
//             el.slug !== 'archiv' &&
//               <li key={i}
//                 className={`${el.slug === 'all' ? (filter === null ? 'hidden' : 'pb-2') : (el.slug === 'wettbewerbe' ? 'mb-2' : '')} ${el.name === filter ? 'underline' : ''} hover:underline underline-offset-4 whitespace-nowrap text-medium`}
//                 onClick={() => handleFilter(el.slug === 'all' ? null : (filter === el.name ? null : el.name))}
//               >
//                 {el.name}
//               </li>
//           )
//         })}
//       </ul>
//     </div>}
// </div>

 {/* { (() => {
    switch (splitLocation[1]) {
      case '':
        // ${menuOpen ? 'relative scale-110 md:scale-100' : ''} 
        return <p className={`text-center`}>Peter W. Schmidt Architekten</p>
        
      case 'projects':
        return <p className={`text-center`}>Projekte</p>
      
      case 'page':
        return <p className={`text-center`}>{pageTitle}</p>
      
      case 'projekt':
        return <p className={`text-center`}>{showProjectHeadline ? 'Projekte' : singleProjectSubtitle}</p>
      
      default:
        return null
    } }) () } */}


    {/* {currentLocation[1] !== '' &&
        <NavLink to='/' className='relative block text-center navitem'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Peter W. Schmidt Architekten</span>
        </NavLink>}
      
      {currentLocation[1] !== 'projects' && 
        <NavLink to="/projects" className='relative block text-center navitem'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Projekte</span>
        </NavLink>}

      {currentLocation[2] !== 'buero' &&
        <NavLink to="/page/buero" className='relative block text-center navitem'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Büro</span>
        </NavLink>}

      {currentLocation[2] !== 'jobs' &&
        <NavLink to="/page/jobs" className='relative block text-center navitem md:mb-4'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Jobs</span>
        </NavLink>}
      
      {currentLocation[2] !== 'kontakt' &&
        <NavLink to="/page/kontakt" className='relative block text-center font-folioLight navitem'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Kontakt</span>
        </NavLink>}
      
      {currentLocation[2] !== 'impressum' &&
        <NavLink to="/page/impressum" className='relative block text-center font-folioLight navitem'>
          <span className='block transition-transform duration-150 md:hover:scale-105'>Impressum</span>
        </NavLink>} */}

      {/* <ul className='flex flex-col list-none md:my-4'>
        <li className='block text-center navitem'><a href='https://www.instagram.com/peter_w.schmidt_architekten/' target='_blank' rel='noreferrer' className='block text-sm transition-transform duration-150 cursor-pointer md:hover:scale-105 text-green font-folioLight'>Instagram</a></li>
        <li className='block text-center navitem'><a href='https://de.linkedin.com/company/peter-w-schmidt-architekten-gmbh' target='_blank' rel='noreferrer' className='block text-sm transition-transform duration-150 cursor-pointer md:hover:scale-105 text-green font-folioLight'>LinkedIn</a></li>
      </ul> */}

//     useEffect(() => {
//   const ctx = gsap.context(() => {
//     // gsap.to(refMenu.current, {
//     //   height: menuOpen ? size.height : menuBtnHeight,
//     //   // height: menuOpen ? refMenuItems.current.offsetHeight : 0,
//     //   duration: .75,
//     //   ease: 'power2.out',
//     // })
    
//     // gsap.from(
//     //   refCurrentPage.current, { 
//     //     autoAlpha: 0, 
//     //     scale: .3,
//     //     ease: 'linear',
//     //     duration: .1,
//     //     delay: menuOpen ? .7 : 0
//     //   }
//     // )        
//   })
  
//   return () => ctx.revert()
// }, [menuOpen]) 