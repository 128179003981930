import { useRef, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"

export default function Searchbar({isSearch, toggleSearch}) {

    const refSearchInput = useRef()

    const [searchTerm, setSearchTerm] = useState('')

    const navigate = useNavigate()
    const params = {search: searchTerm}

    const handleSearchBtn = () => {
        setSearchTerm(refSearchInput.current.value)
        navigate({
            pathname: '/projekte',
            search: `?${createSearchParams(params)}`,
        })  
        // searchProjects(refSearchInput.current.value)
    } 

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            setSearchTerm(event.target.value)
            navigate({
                pathname: '/projekte',
                search: `?${createSearchParams(params)}`,
            })  
            // searchProjects(event.target.value)
        }
    }

    // useEffect(() => {
    //   console.log(searchTerm)  
    // }, [searchTerm])

    return (
        <div className={`fixed z-40 md:z-0 top-10 left-1 right-1 md:right-auto pt-11 pb-2 md:py-0 md:top-auto md:left-auto md:relative block cursor-pointer ${isSearch ? 'md:w-full' : '2xl:w-3/5'} m-auto backdrop-blur-sm bg-transparent`}>
            <div className='flex flex-nowrap'>
                <button 
                    type="button" 
                    className="inline-flex items-center justify-center px-2 text-black hover:text-black focus:outline-none focus-within:shadow-xs focus:ring-0 focus:ring-inset-0"
                    onClick={() => {
                        setSearchTerm('')
                        toggleSearch(false)
                        navigate({pathname: '/projekte'})
                    }}
                >
                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
                    </svg>
                </button>

                <input
                    ref={refSearchInput}
                    type='text'
                    id='search'
                    className='px-1 text-sm bg-transparent lg:text-base xl:px-2 xl:text-base md:w-3/4 xl:w-1/2 grow input focus:outline-0' //border-b border-black
                    placeholder='Suche'
                    name='search'
                    autoFocus
                    onChange={event => setSearchTerm(event.target.value)}
                    onKeyDown={handleKeyDown}
                    value={searchTerm}
                />

                <button 
                    type="button" 
                    className="inline-flex items-center justify-center px-2 text-sm lg:text-base text-black underline leading-[13px] xl:px-4 lg:whitespace-nowrap shrink font-folioLight underline-offset-2 hover:text-black hover:bg-white focus:outline-none focus:ring-0 focus:ring-inset-0"
                    onClick={handleSearchBtn}
                >
                    <span className="inline md:hidden">Suchen</span> 
                    <span className="hidden md:inline">Projekte durchsuchen</span> 
                </button>
            </div>
        </div>
    )
}