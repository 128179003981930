import React from 'react'

const Search = ({isSearch, toggleSearch}) => {
  return (
    <p 
      className={`${isSearch ? 'w-full' : ''} self-start block text-sm lg:text-base text-right md:text-center uppercase px-1 md:px-3 font-folioMedium backdrop-blur-md bg-white/70 md:bg-transparent opacity-95`} // border-b
      onClick={() => toggleSearch(true)}
    >
      Suche
    </p>
  )
}

export default Search