import React, { useEffect, useRef, useState } from 'react'
import Modal from '../Modal/Modal'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useWindowSize } from '@uidotdev/usehooks'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Image = ({
  img, 
  altTxtSubtitle, 
  preview, 
  galleryImg, 
  isModal, 
  isPost, 
  postClamped, 
  setPostClamped
}) => {
    
  gsap.registerPlugin(ScrollTrigger)

  const size = useWindowSize()

  const refImg = useRef(null)

  const [imgLoaded, setImgLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const landscape = img?.width / img?.height > 1 ? true : false

  // useEffect(() => {
  //   ScrollTrigger.refresh() 
  // }, [imgLoaded])

  return (
    img &&
      <>
        <figure ref={refImg}
          className={`relative ${galleryImg ? 'h-full overflow-hidden' : `my-3 ${preview ? 'overflow-hidden ' : ''}`}`}
          style={{ height: !galleryImg ? refImg.current?.clientWidth / (img.width/img.height) : null}}
          onClick={() => isModal && size.width > 768 ? setShowModal(true) : (isPost && postClamped ? setPostClamped(!postClamped) : null)}
        >
          <picture>
            {preview === 'home' || preview === 'projects' || isPost ? 
              <>
                <source
                  media='(max-width: 1679px)'
                  srcSet={img.sizes[preview === 'home' ? 'resp-medium' : 'resp-small']}
                />
                <source
                  media='(min-width: 1680px)'
                  srcSet={img.sizes[preview === 'home' ? 'resp-large' : 'resp-medium']}
                />
              </>
              : 
              <>
                <source
                  media='(min-width: 1920px)'
                  srcSet={`${img.sizes['resp-full']}`}
                />
                <source 
                  media='(min-width: 1280px)'
                  srcSet={`${img.sizes['resp-xlarge']}`}
                />
                <source 
                  media='(min-width: 769px)'
                  srcSet={`${img.sizes['resp-large']}`}
                />
                <source 
                  media='(max-width: 768px)'
                  srcSet={`${img.sizes['resp-small']}`}
                />
              </>
            }              
            <LazyLoadImage
              alt={img.alt ? img.alt : altTxtSubtitle}
              onLoad={() => setImgLoaded(true)}
              src={img.sizes['resp-small']}
              className={`relative ${imgLoaded ? 'blur-none' : 'blur-sm'} ${preview ? 'h-full object-cover md:hover:opacity-100 bg-white md:hover:scale-[1] md:scale-[1.02] transition-all duration-1000 ease-[cubic-bezier(.1,.6,.4,1)]' : ''}${isModal ? ' cursor-pwsCross' : ''} ${galleryImg ? `h-full object-contain object-center` : ``} w-full z-0`}
              // ${!isPost ? 'md:h-screen' : ''}
            />
          </picture>
        </figure>

        {isModal && <Modal showModal={showModal} setShowModal={setShowModal} img={img} landscape={landscape} />}
      </>
  )
}

export default Image