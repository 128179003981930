import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../ThemeContext'
// import { PageScrolledContext } from '../../PageScrolledContext'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

import LogoPeter from './LogoPeter'
import LogoW from './LogoW'
import LogoSchmidt from './LogoSchmidt'
import LogoPW from './LogoPW'
import LogoS from './LogoS'
import gsap from 'gsap'

function Logo({ viewCount }) {

  const { dark, showLogo, intro, menuOpen } = useContext(ThemeContext)
  // const { scroll } = useContext(PageScrolledContext)

  const size = useWindowSize()

  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')

  const refLogo = useRef(null)
  // const tlLogo = useRef(null)
  // const [letterW, setLetterW] = useState(null)
  
  useEffect(() => {
    // setShowLogo(splitLocation[1] === 'projekte' ? (viewCount >= 1 ? false : true) : true)
    // if (splitLocation[1] === 'projekt') {
    //   setShowLogo(false)
    // }

    const ctx = gsap.context(() => {
      let q = gsap.utils.selector(refLogo)
        gsap.to(q('.logo-letter'), { 
          opacity: 1, 
          duration: 0,
          stagger: .1,
          delay: 1
        })

        gsap.to(pathname === '/' || splitLocation[1] === 'page' ? q('.logo-name') : q('.logo-letter'), { 
          opacity: 0, 
          ease: 'linear',
          duration: .3,
          delay: 3
        })
    })

    return () => ctx.revert()
  }, [intro])
  
  // useEffect(() => {
  //   console.log(pathname)
  //   // if (splitLocation[1] === 'projekt') {
  //   //   setShowLogo(false)
  //   // }
  // }, [pathname])

  return (
    <div ref={refLogo} className={`fixed inset-0 pointer-events-none ${intro || !showLogo ? 'opacity-0' : 'opacity-1'} ${showLogo ? `${menuOpen ? 'z-40' : 'z-10' }` : 'z-0'} transition-opacity duration-500 logosPWS`}>
      <div className='relative md:pt-8 px-3 md:pl-8 md:pr-0 w-full md:w-[calc(100%-2rem)] m-0'>
        {size.width > 640 ?
          <LogoPeter showLogo={showLogo} dark={dark} />
          : 
          <LogoPW showLogo={showLogo} dark={dark} />}
        
        <div className='invisible md:visible md:flex md:justify-end absolute top-8 right-3 md:right-0 h-[calc(100%-32px)] w-full'>
          <LogoW showLogo={showLogo} dark={dark} />
        </div>
      </div>

      <div className='absolute bottom-0 pl-3 md:pl-8 w-[calc(100%-2rem)] h-[calc(100%-32px)]'>
        {size.width > 640 ?
          <LogoSchmidt showLogo={showLogo} dark={dark} />
          :
          <LogoS showLogo={showLogo} dark={dark} />
        }
      </div>
    </div>
  )
}

export default Logo