import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const TabbedContent = ({data, content}) => {

  // console.log('content', data === 'awards' ? content : null)
  const [openTab, setOpenTab] = useState(content[0].year)

  return (
    <div className='relative w-full tabs'>
      <div className='flex flex-col w-full my-2'>
        
        <div className='flex overflow-auto gap-x-2 '>
          {content?.map(tab => (
            <p key={tab.id}>
              <span
                onClick={() => setOpenTab(tab.year)}
                className={`${tab.year === openTab ? 'underline underline-offset-4 decoration-1' : ''} block pr-2 text-white text-sm md:text-base cursor-pointer`}
              >
                {tab.year}
              </span>
            </p>
            )
          )}
        </div>

        <div className='mt-3'>
          {content?.map(tab => (
            <div
              key={tab.id}
              id={tab.id}
              className={tab.year === openTab ? 'block' : 'hidden'}
            >
              {data === 'projects' && tab.projects?.map(project => (
                <p key={project.id} className='!mb-1 text-sm md:text-base'>
                  {project.project_link ?
                    <>{project.title} {project.location && <>, {project.location}</>}</>
                  :
                    <Link to={`/projekt/${project.slug}`} preventScrollReset={false} className='block my-2 underline md:my-0 underline-offset-4 decoration-1'>
                      {project.title} {project.location && <span>, {project.location}</span>}
                    </Link> 
                  }
                </p>
              ))}

              {data === 'awards' && tab.awards?.map((award, i) => (
                <div key={i} className='mb-6'>
                  <p className='!mb-1 text-sm md:text-base font-folioMedium'>{award.name}</p>

                  {award.projects?.map(project => (
                    <p key={project.id} className='!mb-1 text-sm my-2 md:my-0 md:text-base'>
                      <Link to={`/projekt/${project.slug}`} preventScrollReset={false} className='block underline underline-offset-4 decoration-1'>
                        {project.name} {project.location && <span>, {project.location}</span>}
                      </Link>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>

      </div>
    </div>
  )
}

export default TabbedContent