import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Modal = ({showModal, setShowModal, img, landscape}) => {
  // const [showModal, setShowModal] = useState(false)
  // console.log(img)

  return (
    <>
      {showModal &&
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden outline-none focus:outline-none">
          <div className="relative w-full h-full">
            <div className="relative flex flex-col w-full h-full bg-white outline-none lg:cursor-pwsClose focus:outline-none" onClick={() => setShowModal(false)}>
              
              <div className="absolute top-0 right-0 z-10 flex items-end justify-end p-5">
                <button 
                  type="button" 
                  className="inline-flex items-center justify-center px-2 text-black bg-transparent hover:text-black focus:outline-none focus:ring-0 focus:ring-inset-0"
                  onClick={() => setShowModal(false)}
                >
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
                  </svg>
                </button>
              </div>

              <div className="relative py-12 px-6 flex-auto max-w-[80%] h-full mx-auto">
                <figure className={`relative h-full overflow-hidden`}>
                  <picture>
                    <source
                      media='(min-width: 1920px)'
                      srcSet={`${img.sizes['resp-full']}`}
                    />
                    <source 
                      media='(min-width: 1280px)'
                      srcSet={`${img.sizes['resp-xlarge']}`}
                    />
                    <source 
                      media='(min-width: 769px)'
                      srcSet={`${img.sizes['resp-large']}`}
                    />
                    <source 
                      media='(max-width: 768px)'
                      srcSet={`${img.sizes['resp-small']}`}
                    />

                    <LazyLoadImage
                      alt={img.alt || ''}
                      height={img.sizes['resp-medium-height']}
                      width={img.sizes['resp-medium-width']} 
                      src={img.sizes['resp-medium']} 
                      className={`relative h-full object-contain w-full z-0`}
                    />
                    {/* <img 
                      src={img.sizes['resp-medium']}
                      loading='lazy'
                      alt={img.alt || ''}
                      className={`relative h-full object-contain w-full z-0`}          
                      // ${landscape ? 'object-cover' : 'object-contain'}
                      // style={{ imgWidth / imgHeight }}
                    /> */}
                  </picture>
                </figure>
              </div>

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Modal

{/* <source
  media="(max-width: 768px)"
  srcSet={`${image.sizes['resp-small']} 768w`}
  sizes="768px"
/>
<source
  media="(max-width: 1440px)"
  srcSet={`${image.sizes['resp-large']} 1440w`}
  sizes="1440px"
/>
<source
  media="(max-width: 1920px)"
  srcSet={`${image.sizes['resp-full']} 1920w`}
  sizes="1920px"
/> */}