import React, { useContext, useEffect, useRef } from 'react'
import { Player, ControlBar, BigPlayButton, VolumeMenuButton } from 'video-react'
import "video-react/dist/video-react.css"
import useWindowSize from '../../useWindowSize'
import { ThemeContext } from '../../ThemeContext'

const Video = ({src, play}) => {
  // const { ww } = useWindowSize()

  const { contentWidth, isLoading, ww } = useContext(ThemeContext)

  const refVideo = useRef(null)
  
  useEffect(() => {
    if (ww > 1194 && refVideo.current) {
      play ? refVideo.current.play() : refVideo.current.pause()
    }
  }, [play])

  return (
    <Player
      ref={refVideo}
      playsInline
      preload='auto'
      src={src}
      // poster="/assets/poster.png"
    >
      <BigPlayButton position="center" className='!border-0 !rounded-none !backdrop-blur-md !bg-transparent backdrop-brightness-1.25 !text-white !w-24 !m-0 !-translate-x-1/2 !-translate-y-1/2' />
      
      <ControlBar 
        // autoHide={true} 
        // autoHideTime={250} 
        className={`${play ? 'opacity-1' : '!opacity-1'} !text-white text-sm !font-folioMedium !backdrop-blur-md !bg-transparent !backdrop-brightness-1.25 !h-8`}
      >
        <VolumeMenuButton vertical className='' />
        {/* !backdrop-blur-md !bg-transparent !backdrop-brightness-1.25 */}
      </ControlBar>
    </Player>
  )
}

export default Video
