import React, { useState, createContext } from "react"

const initialState = {
    showProjectHeadline: null,
    setProjectHeadline: () => {}
}

export const ProjectsHeadlineContext = createContext(initialState)

export const ProjectsHeadlineProvider = ({ children }) => {

    const [showProjectHeadline, setShowProjectHeadline] = useState(null)

    // useEffect(() => {
	// 	console.log('showProjectHeadline', showProjectHeadline)
	// }, [showProjectHeadline])

    return (
        <ProjectsHeadlineContext.Provider value={{showProjectHeadline, setShowProjectHeadline}}>
            {children}
        </ProjectsHeadlineContext.Provider>
    )
}
