import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home/Home'
import Layout from './components/Layout/Layout'
import Page from './components/Page/Page'
import Projects from './components/Projects/Projects'
import SingleProject from './components/SingleProject/SingleProject'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>} >
        <Route index element={<Home/>} />
        <Route path="/projekte" element={<Projects/>} />
        <Route path="/projekt/:slug" element={<SingleProject/>} />
        <Route path="/page/:slug/:postname?" element={<Page/>} />
      </Route>
    </Routes>
  )
}

export default App