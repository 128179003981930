import React, { useState, createContext, useEffect, useContext } from "react"

const themes = {
    light: {
        color: "black",
        background: "transparent",
    },
    dark: {
        color: "white",
        background: "black",
    }
}

const initialState = {
    dark: false,
    theme: themes.light,
    intro: true,
    introEnd: false,
    contentWidth: null,
    menuOpen: false,
    isLoading: null,
    setTheme: () => {},
    setWidth: () => {},
    setMenuOpen: () => {}
}

export const ThemeContext = createContext(initialState)

export const ThemeContextProvider = ({ children }) => {

    const [dark, setDark] = useState(false)
    const [showLogo, setShowLogo] = useState(true)
    const [intro, setIntro] = useState(true)
    const [introEnd, setIntroEnd] = useState(false)
    const [contentWidth, setContentWidth] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const [isLoading, setLoading] = useState(null) 

    const setTheme = val => setDark(val)    

    useEffect(() => {
        document.documentElement.style.backgroundColor = dark ? themes.dark.background : themes.light.background
    }, [dark])
    
    // useEffect(() => {
    //     console.log('showLogo', showLogo)
    // }, [showLogo])
    
    // useEffect(() => {
    //     console.log('contentWidth', contentWidth)
    // }, [contentWidth])
    
    // useEffect(() => {
    //     console.log('menuOpen', menuOpen)
    // }, [menuOpen])

    return (
        <ThemeContext.Provider value={{ dark, showLogo, setShowLogo, intro, setIntro, introEnd, setIntroEnd, menuOpen, setMenuOpen, contentWidth, setTheme, setContentWidth, isLoading, setLoading }}>
            {children}
        </ThemeContext.Provider>
    )
}