import React, { useState, createContext, useEffect } from "react"

const initialState = {
    scroll: null,
    setScroll: () => {}
}

export const PageScrolledContext = createContext(initialState)

export const PageScrolledProvider = ({ children }) => {

    const [scroll, setScroll] = useState({
        isScrolled: null,
        scrollPos: 0,
        scrollDirection: null,
    })

    // useEffect(() => {
	// 	console.log('scroll', scroll)
	// }, [scroll])

    return (
        <PageScrolledContext.Provider value={{ scroll, setScroll }}>
            {children}
        </PageScrolledContext.Provider>
    )
}
