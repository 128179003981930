import React, {useState, useRef}  from 'react'
import Image from '../Image/Image'
import {Link} from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

const Project = ({content, width, marginTop, preview}) => {
  
  const size = useWindowSize()

  const refProject = useRef(null)
  
  const [hoveredProject, setHoveredProject] = useState(null)

  return (
    <div 
      ref={refProject}
      style={size.width > 640 ? {marginTop: marginTop + `vh`} : null}
      onMouseEnter={() => size.width > 1194 ? setHoveredProject(refProject.current) : null}
      onMouseLeave={() => size.width > 1194 ? setHoveredProject(null) : null}
    >
      <Link to={`/projekt/${content.slug}`} preventScrollReset={false} className='block'>
        {content.img ? <Image img={content.img} altTxtSubtitle={content.subtitle} preview={preview} /> : <img src="https://dummyimage.com/600x400/ffffff/000.png&text=PWS" alt='preview' />}

        <div className='relative flex my-3 md:block'>
          <p className={`relative ${hoveredProject ? 'md:opacity-1' : 'md:opacity-0'} transition-opacity duration-500 text-sm md:text-medium`}
             dangerouslySetInnerHTML={{__html: size.width > 640 ? content.title : (content.subtitle ? content.subtitle.concat(' ', content.title) : content.title)}}
          >
          </p>
          
          {content.subtitle && 
            <p className={`hidden md:block md:absolute ${hoveredProject ? 'md:opacity-0' : 'md:opacity-1'} top-0 transition-opacity duration-300 text-sm md:text-medium`}>
              {content.subtitle}
            </p>}
        </div>
      </Link>
    </div>
  )
}

export default Project