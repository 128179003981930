import React from "react"

function LogoPW({showLogo, dark, introEnd}) {
  return (
    <svg
        className={`mt-14 visible md:hidden ${dark ? 'text-white' : 'text-black'}`} //${introEnd ? 'opacity-0': ''} ${showLogo ? 'opacity-1' : 'opacity-0'}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 988 386"
    >
      <path
        d="M265.46 105.404c0 46.593-31.591 69.086-72.831 69.086H41.77V38.534h150.859c41.181 0 72.831 20.375 72.831 66.949m38.259 1.137v-1.157-1.039C303.719 36.416 259.813 0 195.57 0H0v381.846h41.77V213.063h155.173c63.477-.53 106.776-38.985 106.776-106.483"
        className="transition-opacity duration-300 opacity-0 logo-letter logo-name"
      />

      <path
        d="M295.78 338.741H341.78099999999995V384.44H295.78z"
        className="transition-opacity duration-300 opacity-0 logo-letter logo-name"
      />

      <path
        d="M888.762 2.004L817.702 274.264 809.099 310.869 805.324 310.869 796.765 274.264 725.881 2.092 725.881 2.004 725.836 2.004 684.095 2.004 642.441 2.004 642.398 2.004 571.294 274.351 562.735 310.869 558.916 310.869 550.533 274.922 479.297 2.004 437.556 2.004 539.954 384.125 539.999 384.125 581.696 384.125 581.739 384.125 673.781 40.496 694.453 40.496 786.451 383.862 786.363 384.125 786.494 384.125 828.104 384.125 828.237 384.125 828.148 383.862 930.504 2.004 888.762 2.004z"
        className="transition-opacity duration-300 opacity-0 logo-letter logo-name"
      />

      <path
        d="M942.974 339.555H987.827V384.125H942.974z"
        className="opacity-0 transition-opacity duration-300 logo-letter logo-name"
      />
    </svg>
  )
}

export default LogoPW
