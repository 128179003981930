import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Loading from '../Loading/Loading'
import BackToTop from '../BackToTop/BackToTop'
import Projectlist from '../Projectlist/Projectlist'
import { ThemeContext } from '../../ThemeContext'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function Projects() {
  const { isLoading, setLoading } = useContext(ThemeContext)

  const refProjects = useRef(null)

  const [searchParams] = useSearchParams({})
  const [projects, setProjects] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const [wettbewerbe, setWettbewerbe] = useState([])
  const [searchParamVal, setSearchParamVal] = useState(null)
  const [filter, setFilter] = useState(null)

  async function getProjects(searchParamVal) {
    console.log('searchParamVal ', searchParamVal)
    // const queryURL = searchParamVal?.search !== undefined ? `https://cms.pws.eu/wp-json/pws/v1/search?s=${searchParamVal.search}` : `https://cms.pws.eu/wp-json/pws/v1/projects`
    const queryURL = searchParamVal != null ? `https://cms.pws.eu/wp-json/pws/v1/search?s=${searchParamVal?.search}` : `https://cms.pws.eu/wp-json/pws/v1/projects`

    const response = await fetch(queryURL)
    if (!response.ok) return

    const projectResults = await response.json()
    setAllProjects(projectResults)
  }

  const getFilteredProjects = (projects, excludedProjects, filter) => {
    // console.log('excludedProjects ', excludedProjects)
    // console.log('getFilteredProjects --> filter ',projects, filter)
    switch (filter) {
      case null:
        return setProjects(projects.filter(el => !excludedProjects.find(element => element.id === el.id)))

      case 'WETTBEWERBE':
        return setProjects(allProjects.filter(el => el.categories?.includes(filter)))

      default:
        return setProjects(projects.filter(el => !excludedProjects.find(element => element.id === el.id) && el.categories?.includes(filter)))
    }
  }

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams])
    // console.log('currentParams', currentParams)
    if (Object.keys(currentParams).length !== 0) {
      setSearchParamVal(currentParams)
    }
  }, [searchParams])

  useEffect(() => {
    // console.log('searchParamVal', searchParamVal)
    if (searchParamVal != null) {
      setFilter(searchParamVal?.category ? searchParamVal.category : null)
      getProjects(searchParamVal)
    } else {
      getProjects()
    }
  }, [searchParamVal])

  useEffect(() => {
    // console.log('All', allProjects)
    const projectsWettbewerbe = allProjects?.filter(project => project.categories?.length === 1 && project.categories?.includes('WETTBEWERBE'))
    setWettbewerbe(projectsWettbewerbe)
    setLoading(false)
  }, [allProjects])

  useEffect(() => {
    // console.log('Wettbewerbe', wettbewerbe)
    getFilteredProjects(allProjects, wettbewerbe, filter)
    // projectResults?.filter(project => project.categories.length === 1 && project.categories?.includes('wettbewerbe'))
    // searchParamVal.category !== undefined ? searchParamVal?.category
  }, [wettbewerbe])

  // useEffect(() => {
  //   // console.log('Projects', projects)
  //   // ScrollTrigger.refresh()
  // }, [projects])
  
  useEffect(() => {
    console.log('filter', filter)
    getFilteredProjects(allProjects, wettbewerbe, filter)
  }, [filter])

  // useEffect(() => {
  //   setLoading(true)
  // }, [])

  return (
    <BackToTop>
      <div ref={refProjects} className={`relative flex flex-wrap md:justify-between pt-[12vh] md:pt-[22.5vh] px-5 md:px-8 lg:px-12 xl:px-24`}> 
        {projects && <Projectlist projects={projects} />}
      </div>
    </BackToTop>
  )
}

export default Projects