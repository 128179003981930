import React, { useState, useRef, useEffect, useContext } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'
import { useFetch } from '../../useFetch'
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent'
import PageContent from '../PageContent/PageContent'
// import PWSContactMap from './PWSContactMap'

const Page = () => {

  const { slug, postname } = useParams()
  const { pathname } = useLocation()
  
  const collapsibleOpen = postname //state?.jobId ||
  
  const splitLocation = pathname.split('/')
  
  const contentRefs = useRef([])
  const refPage = useRef(null)

  const { isLoading, setLoading, contentWidth } = useContext(ThemeContext)
  // const { theme, intro, setIntro, setShowLogo, setTheme, isLoading, setLoading } = useContext(ThemeContext)
  
  const [pageContent, setPageContent] = useState(null)
  const [jobsPage, setJobsPage] = useState(null)
  const [jobPosts, setJobPosts] = useState(null)
  const [awardsPage, setAwardsPage] = useState(null)
  const [awardPosts, setAwardPosts] = useState(null)
  // const [archivePosts, setArchivePosts] = useState(null)
  
  const jobs = useFetch('https://cms.pws.eu/wp-json/wp/v2/jobs')
  const awards = useFetch('https://cms.pws.eu/wp-json/pws/v1/awards') 
  // const archive = useFetch('https://pws.vonheintschel.dev/wp-json/pws/v1/projects?archive')

  useEffect(() => {
    // console.log('location', pathname)
    // console.log('slug', slug)
    // console.log('postname', postname)
    // console.log('state', state)
    // console.log('collapsibleOpen', collapsibleOpen)

    fetch(`https://cms.pws.eu/wp-json/wp/v2/pages/?slug=${slug}`) 
      .then(res => res.json())
      .then(data => {
        setPageContent(data[0]?.acf.buro_content)
        setLoading(false)
      })

    fetch('https://cms.pws.eu/wp-json/pws/v1/menuItems')
      .then(res => res.json())
      .then(data => {
        setAwardsPage(data['awardsPage'])
        setJobsPage(data['jobsPage'])
      })
  }, [slug])
  
  useEffect(() => {
    // console.log('jobs', jobs)
    // console.log('pageContent', pageContent)
    // console.log('pageJobs', pageJobs)
    // console.log('archive', archive) 

    if (!jobs.loading) 
      setJobPosts(jobs?.data)
    
    if (!awards.loading)
      setAwardPosts(awards?.data)
    // console.log('awards', awards)
    
    // if (!archive.loading) {
    //   setArchivePosts(archive?.data)
    // }
  }, [jobs, awards, pageContent]) //archive
  
  return (
    <div ref={refPage} className={`relative min-h-screen px-0 text-sm text-left ${isLoading ? 'opacity-0' : 'opacity-1'} page`}> 
      <div className='flex flex-wrap gap-x-12 contentWidth md:mx-auto pt-14 md:pt-[22.5vh] pb-16 md:pb-[25vh] px-5 md:px-4 xl:px-12' style={{width: contentWidth}}>

        {/* {(slug === 'buero' || slug === 'impressum') &&
          <div className='relative flex justify-between w-full mb-4 cursor-pointer md:px-4 md:hidden'>
            <div className='relative'>
              <p className='!mb-1'>Socials</p>

              <div className='flex flex-col'>
                <a href='https://de.linkedin.com/company/peter-w-schmidt-architekten-gmbh' target='_blank' rel='noreferrer' className='block mb-1 text-sm underline cursor-pointer underline-offset-2 decoration-1'>LinkedIn</a>
                <a href='https://www.instagram.com/peter_w.schmidt_architekten/' target='_blank' rel='noreferrer' className='block text-sm underline cursor-pointer underline-offset-2 decoration-1'>Instagram</a>
              </div>
            </div> 
            
            <NavLink
              className={`md:hidden relative ${splitLocation[2] === 'impressum' ? 'underline underline-offset-2' : ''}`}
              to={splitLocation[2] === 'impressum' ? '/page/buero' : '/page/impressum'}
            >
              {splitLocation[2] === 'impressum' && 
                <button 
                  type="button"
                  className="absolute inline-flex items-center justify-center text-white -translate-y-px bg-transparent top-1 md:top-0 -left-7 md:left-2 hover:text-white focus:outline-none focus:ring-0 focus:ring-inset-0"
                >
                  <svg className="w-4 h-4 md:w-6 md:h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
                  </svg>
                </button>}
              Impressum
            </NavLink>
          </div>} */}

        <PageContent contentItems={pageContent} />

        {slug === 'jobs' && jobPosts && pageContent && 
          <div className='relative w-full mb-8'>
            {jobPosts?.map(job => (
              <CollapsibleContent collapsibleHeader={job.title.rendered} type={'page'} elKey={job.slug} isOpen={collapsibleOpen}>
                <p className='mb-0 text-sm'>{job.acf.job_buro}</p>

                {job.acf.job_description && <div className={`relative w-full md:flex md:flex-col`} dangerouslySetInnerHTML={{__html: job.acf.job_description}}></div>}

                {job.acf.job_pdf && <p className='text-sm md:text-base'><a target='_blank' rel='noreferrer' href={job.acf.job_pdf} className='text-sm underline underline-offset-2'>Jobbeschreibung</a></p>}
              </CollapsibleContent>
            ))}
          </div>}

        {(slug === jobsPage && jobPosts && pageContent) && 
          <div 
            id={pageContent.length} 
            key={pageContent.length} 
            ref={el => contentRefs.current[pageContent.length] = el} 
            className={`relative w-full`}
          >
            <CollapsibleContent content={null} pageTitle={'Jobs / Karriere'} pageContent={jobPosts} type={'page'} elKey={pageContent.length} />
          </div>}
        
        {slug === awardsPage && awardPosts && pageContent && 
          <div 
            id={pageContent.length + 1} 
            key={pageContent.length + 1} 
            ref={el => contentRefs.current[pageContent.length + 1] = el} 
            className={`relative w-full`}
          >
            <CollapsibleContent content={null} pageTitle={'Auszeichnungen'} pageContent={awardPosts} type={'tabs'} elKey={pageContent.length + 1} />
          </div>}
        
        {/* {slug === 'buero' && archivePosts && pageContent && 
          <div 
            id={pageContent.length + 2} 
            key={pageContent.length + 2} 
            ref={el => contentRefs.current[pageContent.length + 2] = el} 
            className={`relative w-full px-4 xl:px-12 text-sm text-white text-left`}
          >
            <CollapsibleContent content={null} pageTitle={'Archiv'} pageContent={archivePosts} type={'tabs'} elKey={pageContent.length + 2} />
          </div>
        } */}
      </div>
    </div>
  )
}

export default Page



// const PWSMapComponent = withScriptjs(withGoogleMap((props) =>
//   <GoogleMap
//     defaultZoom={8}
//     defaultCenter={{ lat: props.mapLat, lng: props.mapLng }}
//   >
//     {/* {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />} */}
//   </GoogleMap>))
// {/* {el.buro_contact_pforzheim && 
//     <div className='mb-3'>
//       <PWSContactMap
//         mapLat={parseFloat(el.buro_map_location_lat_pforzheim)}
//         mapLng={parseFloat(el.buro_map_location_long_pforzheim)}
//         zoom={11}
//       />
//     </div>
//   } */}
  
//   {/* {el.buro_contact_berlin && 
//     <div className='mb-3'>                      
//       <PWSContactMap
//         mapLat={parseFloat(el.buro_map_location_lat_berlin)}
//         mapLng={parseFloat(el.buro_map_location_long_berlin)}
//         zoom={15}
//       />
//     </div>
//   } */}