import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
import Gallery from '../Gallery/Gallery'
import Video from '../Video/Video'
import { useWindowSize } from '@uidotdev/usehooks'

const Post = ({content}) => {
  gsap.registerPlugin(ScrollTrigger)

  const refPost = useRef(null)
  const refSubtitle = useRef(null)

  const size = useWindowSize()
  
  const [inViewport, setInViewport] = useState(false)
  const [hoveredPost, setHoveredPost] = useState(null)
  const [postClamped, setPostClamped] = useState(true)

  const postScrollTrigger = () => {
    ScrollTrigger.create({
      trigger: refPost.current,
      start: `top center`,
      end: `bottom top`,
      onEnter: () => setInViewport(!inViewport),
      onEnterBack: () => setInViewport(!inViewport),
      onLeave: () => setInViewport(!inViewport),
      onLeaveBack: () => setInViewport(!inViewport)
    })
  }

  useEffect(() => {
    postScrollTrigger()
  }, [])

  return (
    <div ref={refPost} className={`relative px-2 transition-colors md:px-0`}>
      {/* <div className='absolute z-0 h-full inset-x-2 md:inset-0 bg-grey-opac'></div>  */}
      
      <div className='relative pb-4 md:pb-2 z-10 bg-transparent md:backdrop-blur-lg md:backdrop-brightness-1.25'
        onMouseEnter={() => size.width > 640 && setHoveredPost(true)}
        onMouseLeave={() => size.width > 640 && setHoveredPost(false)}
      >        
        <div className='relative text-sm post-text'>
          {content.img?.length > 1 &&
            <div className='my-4'>
              <Gallery 
                images={content.img} 
                type={'fade'} 
                pagination={false} 
                arrows={false} 
                autoplay={true} 
                autoPlayActive={inViewport} 
                isPost={true} 
                ratio={parseFloat(content.img[0]?.height / content.img[0]?.width).toFixed(2)} 
              /> 
            </div>}

          {content.img?.length === 1 && 
            <Image 
              img={content.img ? content.img[0] : null} 
              preview={true}
              isModal={false} 
              isPost={true} 
              postClamped={postClamped} 
              setPostClamped={setPostClamped} 
            />}
          
          {content.video && <div className='mb-4'><Video src={content.video} play={inViewport} /></div>}
            
          {/* <p ref={refSubtitle} className='!mb-1 text-sm text-left'>
            {!hoveredPost && <><span>{content.subtitle}</span><span className='block md:hidden'>{content.news_subtitle_hover}</span></>}
            {hoveredPost && <span>{content.news_subtitle_hover ? content.news_subtitle_hover : content.postdate}</span>}
          </p> */}
          
          <h3 className={`font-folioMedium ${content.headlineLarge ? 'text-base' : 'text-medium'} ${hoveredPost ? 'text-green' : 'text-black'}`}>
            {content.title}
          </h3>
          
          {content.txt && 
            <ReadMoreText text={content.txt} hasLink={content.news_link ? true : false} type={'news'} isText={true} postClamped={postClamped}>
              {content.news_link && <SwitchLinkType link={content.news_link} />}
            </ReadMoreText>}
        </div>
        
      </div>
    </div>
  )
}

export default Post

const SwitchLinkType = ({link}) => {
  switch (link.post_type) {
    case 'projects':
      return (
        <Link to={`/projekt/${link.post_name}`} className="block text-sm underline !decoration-green text-green font-folioMedium px-0">
          zum Projekt
        </Link>
      )

    case 'job':
      return (
        <Link to={`/page/jobs/${link.post_name}`} className="block text-sm underline !decoration-green text-green font-folioMedium px-0">
          zur Stellenausschreibung
        </Link>
      )

    case 'page':
      return (
        <Link to={`/page/${link.post_name}`} className="block text-sm underline !decoration-green text-green font-folioMedium px-0">
          {link.post_title}
        </Link>
      )

    default:
      return null;
  }
}