import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../ThemeContext'
import Post from '../Post/Post'
import Project from '../Project/Project'
import BackToTop from '../BackToTop/BackToTop'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

function Home() {

  const refHome = useRef(null)
  const refHomeItems = useRef([])

  const { setTheme, setLoading } = useContext(ThemeContext)

  const [homeContent, setHomeContent] = useState(null)

  useEffect(() => {
    setLoading(true)

    fetch('https://cms.pws.eu/wp-json/pws/v1/homePosts')
      .then(res => res.json())
      .then(data => {
        setTheme(false)
        setHomeContent(data)
        setLoading(false)
      })
  }, [])

  // useEffect(() => {
  //   ScrollTrigger.refresh()
  // }, [homeContent])

  return (
    <BackToTop>
      <div ref={refHome} className={`relative flex flex-wrap md:justify-between pt-11 md:pt-[17.5vh] px-5 md:px-8 lg:px-12 xl:px-24`}>
        {/* ${introEnd ? 'opacity-1' : 'opacity-0'} transition-opacity duration-300 */}
        {homeContent?.items?.map((el, i) => {
          return (
            <>
              <div
                key={i}
                ref={refEl => refHomeItems.current.push(refEl)}
                className={`relative w-full md:w-[${el.content_el_width + `%`}] ${i === 0 && homeContent?.first_grid_item_centered ? 'md:mx-auto' : ''} ${el.posttype !== 'spacer' ? 'mb-8 md:mb-12 lg:mb-16 xl:mb-24' : 'md:mx-0'} ${el.posttype} ${el.posttype === 'projects' ? 'px-2 md:px-6 lg:px-8 xl:px-12 z-0' : 'md:mx-[2.1%] z-20'}`}//4.167%
              >  
                {el.posttype === 'projects' && <Project content={el} width={el.content_el_width} marginTop={el.content_el_margin_top} preview={'home'}/>}
                {el.posttype === 'post' && <Post content={el} />}
              </div>

              {(i === 0 && homeContent?.first_grid_item_centered) && <div key={'forceNewRow'} className='relative basis-full grow'/>}
            </>
          )
        })}
      </div>
    </BackToTop>
  )
}

export default Home