import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

const ScrollToTop = () => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  useEffect(() => {
    gsap.to(window, {
      duration: .5,
      scrollTo: 0,
      ease: 'sine.out',
    })
  }, [])

  return null
}

export default ScrollToTop