import React from 'react'
import Project from '../Project/Project'

const Projectlist = ({projects}) => {
  return (
    projects?.length >= 1 && projects?.map((el, i) => {
      return (
        <div
          key={i}
          className={`block relative project md:px-2 xl:px-4 mb-8 md:mb-6 lg:mb-12 w-full md:w-1/5 cursor-pointer`} // ${el.content_el_width ? el.content_el_width : '2'}/12
        >
          <Project content={el} preview={'projects'}/>
        </div>
      )
    }) 
  )
  // else {
  //   return (
  //     // <p>Keine Projekte</p>
  //   )
  // }
}

export default Projectlist