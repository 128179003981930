import React, { useContext, useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'
import { useWindowSize } from '@uidotdev/usehooks'
import Image from '../Image/Image'

import '@splidejs/react-splide/css'
import arrow from '../../assets/arrow.svg'

const Gallery = ({images, type, fullscreen, ratio, autoplay, pagination, arrows, autoPlayActive, isPost}) => {

  const size = useWindowSize()
  const { setShowLogo } = useContext(ThemeContext)
  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')

  const refGallery = useRef(null)
  const refBtnPrev = useRef()
  const refBtnNext = useRef()
  
  const [hoveredArrow, setHoveredArrow] = useState(null)

  useEffect( () => {
    if (refGallery.current) {
      autoPlayActive ? refGallery.current.splide.Components.Autoplay.play() : refGallery.current.splide.Components.Autoplay.pause()
    }
  }, [autoPlayActive])

  return (
    <Splide 
      ref={refGallery}
      tag='section'
      hasTrack={false}
      className={`relative z-0 w-full ${fullscreen ? 'md:w-full md:aspect-videos h-full' : 'h-auto'}`} 
      aria-label='Bildergalerie'
      options={{
        heightRatio: ratio ? ratio : false,
        type: type? type : 'loop', 
        speed: type === 'fade' && autoplay ? 500 : 1000,
        autoplay: autoplay ? true : false,
        pauseOnHover: false,
        interval: 2500,
        rewind: true,
        arrows: false,
        pagination: false //pagination ? true : false
      }}
      onMounted={splide => splide.Components.Autoplay.pause()}
      onMove={() => splitLocation.includes('projekt') && setShowLogo(false) }
    >
      <SplideTrack>
        {images?.map((el, i) => 
          <SplideSlide key={i} >
            <Image img={el} galleryImg={true} isPost={isPost} preview={type === 'fade' ? true : false} />
          </SplideSlide>)}
      </SplideTrack>

      {arrows && size.width > 1024 &&
        <div className="absolute top-0 z-10 w-full h-full text-black splide__arrows mix-blend-overlay ">
          <button ref={refBtnPrev} 
            onClick={ () => refGallery.current?.splide.Components.Controller.go('<')}
            onMouseEnter={() => setHoveredArrow(refBtnPrev)}
            onMouseLeave={() => setHoveredArrow(null)}
            className="splide__arrow splide__arrow--prev absolute !top-1/2 !w-1/5 !h-full !left-8 !bg-transparent text-left !opacity-100 !z-40 cursor-pointer focus:!outline-none"
          >
            <img src={arrow} 
              aria-labelledby="Previous" 
              className={`absolute left-0 w-auto h-1/4 lg:h-[15%] ${hoveredArrow === refBtnPrev ? 'opacity-100' : 'opacity-0'}`}
            />
          </button>

          <button ref={refBtnNext}  
            onClick={ () => refGallery.current?.splide.Components.Controller.go('>')}
            onMouseEnter={() => setHoveredArrow(refBtnNext)}
            onMouseLeave={() => setHoveredArrow(null)}
            className="splide__arrow splide__arrow--next absolute top-1/2 !w-1/5 !h-full !right-8 !bg-transparent text-right !opacity-100 !z-40 !rotate-180 -translate-y-1/2 origin-center cursor-pointer focus:!outline-none"
          >            
            <img src={arrow} 
              aria-labelledby="Previous" 
              className={`absolute w-auto left-0 h-1/4 lg:h-[15%] ${hoveredArrow === refBtnNext ? 'opacity-100' : 'opacity-0'}`}
            />
          </button>
        </div>
      }
    </Splide>
  )
}

export default Gallery