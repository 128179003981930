import React, { useContext, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ThemeContext } from '../../ThemeContext'
// import { PageScrolledContext } from '../../PageScrolledContext'
// import Loading from '../Loading/Loading'
import usePageBottom from './../../usePageBottom'

// import { ProjectsHeadlineContext } from '../../ProjectsHeadlineContext'

const BackToTop = ({children}) => {
  // gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  const reachedBottom = usePageBottom()

  const refContainer = useRef(null)

  // const { intro } = useContext(ThemeContext)

  const [scrollTop, setScrollTop] = useState(false)

  // const backtoTop = () => {
  //   ScrollTrigger.create({
  //     trigger: refContainer.current,
  //     start: 'top top',
  //     end: 'bottom bottom-=1px',
  //     toggleActions: 'play none none none',
  //     // markers: true,
  //     onEnter: () => {
  //       setScrollTop(false)
  //       // setShowProjectHeadline(false)
  //     },
  //     onLeave: () => {
  //       setScrollTop(true)
  //       // setShowProjectHeadline(false)
  //     } 
  //   })
  // }

  useEffect(() => {
    if (scrollTop) {
      gsap.to(window, {
        duration: .5,
        scrollTo: 0,
        delay: .5,
        onComplete: () => setScrollTop(false)
      })
    }
  }, [scrollTop])
  
  useEffect(() => {    
    setScrollTop(reachedBottom ? true : false)
  }, [reachedBottom])

  return (
    <div ref={refContainer} className={`relative pb-[50vh] lg:pb-[75vh] ${scrollTop ? 'opacity-0' : 'opacity-1'} transition-opacity duration-300 ease-in-out`}>
      {/* || intro */}
      {children}
    </div>
  )
}

export default BackToTop